var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-radio-group" },
    [
      _c(
        "van-radio-group",
        {
          model: {
            value: _vm.radio,
            callback: function ($$v) {
              _vm.radio = $$v
            },
            expression: "radio",
          },
        },
        [
          _c(
            "van-cell-group",
            _vm._l(_vm.options, function (option, i) {
              return _c(
                "van-cell",
                {
                  key: i,
                  attrs: { title: option.label, clickable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.select(option)
                    },
                  },
                },
                [
                  _c("van-radio", {
                    attrs: { slot: "right-icon", name: option.value },
                    slot: "right-icon",
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }