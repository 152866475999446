<template>
  <div class="cell-radio-group">
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          v-for="(option, i) in options"
          :key="i"
          :title="option.label"
          clickable
          @click="select(option)"
        >
          <van-radio slot="right-icon" :name="option.value" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
export default {
  name: 'cell-radio-group',
  components: {},
  props: {
    radio: {
      type: String || Number,
      default: () => ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    select(option) {
      this.$emit('update:radio', option.value)
      this.$emit('select')
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="stylus" scoped>
.oa-approval-user
  position relative
</style>
